<template lang="">
         <HeaderV/>
   <div class="container">
        <div class="card text-start">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-9">
                        <div class="card text-start">
                            <div class="card-header">
                                <h4 class="card-title">TUBO CÉDULA</h4>
                            </div>
                            <div class="card-body">
                                <p class="card-text">
                                    Actualmente se fabríca <span class="text-danger">Tubería Cédula 30</span> en los siguientes diámetros:
                                </p>
                                <div class="container">
                                    <p class="card-text ventaja"><i
                                            class="fa-regular fa-arrow-right text-danger"></i><span class="text-danger">¾”</span> (1.050), <span class="text-danger">1 ¼”</span> (1.660), <span class="text-danger">1”</span> (1.315) y <span class="text-danger">1 ½”</span> (1.900).
                                    </p>

                                </div>
                               


                            </div>
                        </div>


                    </div>
                    <div class="col-lg-3">
                        <div class="card text-start">
                            <div class="card-body">
                                <router-link to="/mueblero">
                  <img src="../assets/img-difmerc.jpg" class="img-responsive" alt="Image" style="width: 100%;">
                </router-link>

                                <div class="card text-start "
                                    style="background:rgb(10, 10, 80);color: #fff;margin-top: 10px;">
                                    <div class="card-header">
                                        <h5 class="card-title">PRODUCTOS</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="btn-group-vertical " role="group"
                                            aria-label="Vertical button group">
                                            <router-link to="/rolada" class="boton">Tubería Rolada en Fría</router-link>
                           <router-link to="/galvanizado" class="boton">Tubo Galvanizado</router-link>
                           <router-link to="/tubog" class="boton">Tubo G-90</router-link>
                          <router-link to="/cedula" class="boton">Tubería Rolado en caliente & PTR</router-link>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <FooterV/>
</template>
<script>
import HeaderV from '@/components/HeaderV.vue'

import FooterV from '@/components/FooterV.vue'
export default {
    name: 'CedulaV',
  props: {
  },
  components: {
    HeaderV,
    FooterV
  }
}
</script>
<style>
/* .ventaja {
    line-height: 0px;
    font-size: 15px;
    height: 10px;
} */

.boton {
    background: rgb(10, 10, 80);
    color: #fff;
}

thead {
    background-color: rgb(176, 0, 0);
    color: #fff;
}

.accordion-button {
    background: rgb(56, 56, 56);
    color: #fff;
}
</style>