<template lang="">
   <HeaderV/>
  <div class="container">
    <div class="card text-start">
      <div class="card-header">
        <h4 class="card-title">PREGUNTAS FRECUENTES</h4>
      </div>
      <div class="card-body">
        <div class="row">

          <div class="col-lg-8">
            <div class="card text-white bg-danger mb-3">
              <div class="card-body">
                <h5 class="card-title">¿SE TIENE UNA CANTIDAD MÍNIMA PARA ENTREGA A DOMICILIO?</h5>
                <p class="card-text">En Flexometal se venden atados completos, es decir, no piezas sueltas, cada medida
                  contiene un número específico de piezas, por lo que, establecemos como venta minima un paquete del
                  material que nuestro cliente esté requiriendo.</p>
                <p class="card-text">Adicional a este aspecto, es importante mencionar que si el peso del pedido es
                  inferior a una tonelada, el pedido queda en espera de que en la ruta de logística esté planeada hacía
                  donde se encuentra la dirección de entrega.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="card text-white bg-dark mb-3">
              <div class="card-body">
                <h5 class="card-title">¿EXISTE ALGÚN COSTO ADICIONAL PARA ENTREGAR DOMICILIO?</h5>
                <p class="card-text">Flexometal no existe costo adicional por entregar a domicilio.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-7">
            <div class="card text-white bg-dark mb-3">
              <div class="card-body">
                <h5 class="card-title">¿CUÁL ES EL TIEMPO DE ENTREGA?</h5>
                <p class="card-text">El tiempo de entrega depende de diversas situaciones como pueden ser:</p>
                <div class="container">
                  <p class="detalle"><i class="fa-solid fa-share"></i> Si la ubicación del cliente es foránea, el tiempo
                    de entrega puede oscilar entre 48 a 72 hrs.</p>
                  <p class="detalle"><i class="fa-solid fa-share"></i> Si es pedido local y el peso del pedido es
                    superior a una tonelada, el tiempo de entrega es de 24 hrs.</p>
                </div>
                <p class="card-text">De cualquier manera sea el pedido local o foráneo, el departamento de Atención al
                  Cliente, confirmará el día de entrega con cada cliente vía correo electrónico y vía telefónica.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-5">
            <div class="card text-white bg-danger mb-3">
              <div class="card-body">
                <h5 class="card-title">¿SE PUEDE RECOGER MATERIAL DIRECTAMENTE EN LA PLANTA DE FLEXOMETAL?</h5>
                <p class="card-text">El cliente que así lo desee puede recoger en planta su pedido, el horario de cargas
                  de material es de 8:00 am a 5:00 p.m de L-V y de 8:00 am a 12:00 p.m. los días Sábado. La única
                  solicitud que se le hace al cliente es avisar con 2 hrs de anticipación para programar su llegada y
                  evitar tardanzas en la carga de su material.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="card text-white bg-danger mb-3">
              <div class="card-body">
                <h5 class="card-title">¿ENTREGAN EN TODO EL PAÍS?</h5>
                <p class="card-text">Actualmente hemos realizado entregas en la mayoría del territorio nacional, cada
                  cliente que se comunica a la empresa que se considera foráneo se realiza cotización del flete para
                  poder entregar en la zona en la que el cliente solicita.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="card text-white bg-dark mb-3">
              <div class="card-body">
                <h5 class="card-title">¿QUÉ MÉTODOS DE PAGO ACEPTAN?</h5>
                <p class="card-text">Contamos con cuentas en diversos bancos para mayor comodidad del Cliente, para que
                  si así lo prefiere realice transferencia bancaria o depósito en Ventanilla.</p>
                <p class="card-text">Al realizar su cotización en línea o ponerse en contacto con nosotros para
                  concretar la compra de producto, el Ejecutivo de Atención al Cliente le proporcionará la información
                  necesaria para su pago.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="card text-white bg-danger mb-3">
              <div class="card-body">
                <h5 class="card-title">¿EN QUÉ CONSISTE EL CONCEPTO CARGO POR MANIOBRAS?</h5>
                <p class="card-text">Este cargo esta basádo en las maniobras y/o movimientos que se realizan al interior
                  de la planta para mover el material de donde se encuentra almacenado al vehículo que transportará el
                  material, este cargo aplica tanto en pedidos a domicilio como en cargas con unidades del cliente en la
                  planta de la empresa.</p>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
  <FooterV/>
</template>
<script>
import HeaderV from '@/components/HeaderV.vue'

import FooterV from '@/components/FooterV.vue'
export default {
    name: 'PreguntasFrecuentesV',
  props: {
  },  components: {
    HeaderV,
    FooterV
  }
}
</script>
<style lang="">
    
</style>