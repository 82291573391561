<template lang="">
        <HeaderV/>
 <div class="container">
        <div class="card text-start">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-9">
                        <div class="card text-start">
                            <div class="card-header">
                                <h4 class="card-title">TUBO G-90</h4>
                            </div>
                            <div class="card-body">
                                <p class="card-text">
                                    La tubería que actualmente se fabrica para el segmento de <span
                                        class="text-damger">Invernadero – Macrotunel</span> son:
                                </p>
                                <div class="container">
                                    <p class="card-text ventaja"><i
                                            class="fa-regular fa-arrow-right text-danger"></i>Tubos redondos de
                                        <span class="text-danger">diámetro exterior</span>: 5/8”, ¾”, 1”, 1.315”, 1 ½”,
                                        1 5/8”, 1 7/8”, 2”, 2 3/8”, 2 ½”, 2 7/8”, 3”, 3 ½” y 40 MM.
                                    </p>
                                    <p class="card-text ventaja"><i class="fa-regular fa-arrow-right text-danger"></i>
                                        <span class="text-danger">Tubos Cuadrados</span>: 1 x 1”, 1 ½ x 1 ½”, 1 ¼ x 1
                                        ¼”, 2 x 2”, 2 ½” x 2 ½”, 3 x 3”.
                                    </p>
                                    <p class="card-text ventaja"><i
                                            class="fa-regular fa-arrow-right text-danger"></i>Largos desde
                                        <span class="text-danger">4 m. a 11 m</span>.
                                    </p>

                                </div>
                                <p class="card-text" style="margin-top: 20px;">
                                    Los calibres utilizados de línea son 11, 12, 13, 14, 16, 18, y 20.
                                </p>


                            </div>
                        </div>


                    </div>
                    <div class="col-lg-3">
                        <div class="card text-start">
                            <div class="card-body">
                                <router-link to="/mueblero">
                  <img src="../assets/img-difmerc.jpg" class="img-responsive" alt="Image" style="width: 100%;">
                </router-link>

                                <div class="card text-start "
                                    style="background:rgb(10, 10, 80);color: #fff;margin-top: 10px;">
                                    <div class="card-header">
                                        <h5 class="card-title">PRODUCTOS</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="btn-group-vertical " role="group"
                                            aria-label="Vertical button group">
                                            <router-link to="/rolada" class="boton">Tubería Rolada en Fría</router-link>
                           <router-link to="/galvanizado" class="boton">Tubo Galvanizado</router-link>
                           <router-link to="/tubog" class="boton">Tubo G-90</router-link>
                          <router-link to="/cedula" class="boton">Tubería Rolado en caliente & PTR</router-link>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <FooterV/>
</template>
<script>
import HeaderV from '@/components/HeaderV.vue'

import FooterV from '@/components/FooterV.vue'
export default {
    name: 'TubogV',
  props: {
  },
  components: {
    HeaderV,
    FooterV
  }
}
</script>
<style>
/* .ventaja {
    line-height: 0px;
    font-size: 15px;
    height: 10px;
} */

.boton {
    background: rgb(10, 10, 80);
    color: #fff;
}

thead {
    background-color: rgb(176, 0, 0);
    color: #fff;
}

.accordion-button {
    background: rgb(56, 56, 56);
    color: #fff;
}
</style>