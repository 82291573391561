<template>

  

    

  <router-view/>
</template>


<style>
        body {
            border-top: 5px solid #000000;
          
            background-repeat: no-repeat;
            background-size: 100%;
            height: 100%;


        }

        /* .boton-nav {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: #a8adb1;
            text-align: center;
            text-decoration: none;
            font-size: large;
            font: bolder;
        }

        .boton-nav:hover {
            transition: transform 0.5s;
            border-color: #505153;
            background-color: #505153;


        }

        .boton-nav:click {
            border-color: #505153;
            background-color: #505153;
        } */

        li a {
            font-size: 15px;
            margin-left: 30px;
            /* border: solid 1px black; */
        }
    </style>



