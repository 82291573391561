<template lang="">
    <HeaderV/>
    <div class="container">
    <div class="card text-start">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-9">
            <div class="card text-start">
              <div class="card-header">
                <h4 class="card-title">TUBO GALVANIZADO</h4>
              </div>
              <div class="card-body">
                <p class="card-text">
                  Las medidas que actualmente fabricamos son:
                </p>
                <div class="container">
                  <p class="card-text ventaja"><i class="fa-regular fa-arrow-right text-danger"></i>Tubos redondos de
                    <span class="text-danger">diámetro exterior</span>: 1.315", 1½, 1 5/8", 17/8", 2 3/8", 2 7/8".
                  </p>
                  
                </div>
                <p class="card-text" style="margin-top: 10px;">
                  Los calibres utilizados son <span class="text-danger">16, 18, 20 y 22</span>. Calibres diferentes a estos pueden fabricarse <span class="text-danger">previa consulta</span> con su Asesor
                  Comercial.
                </p>

                <div class="container">
                  <!--  -->
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          PAQUETES PARA VENTA DE TUBO
                        </button>
                      </h2>
                      <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">

                          <div class="row">
                            <div class="col-lg-12">
                              <table class="table">
                                <thead >

                                  <tr>
                                    <td><strong>Tipo Tubo</strong>
                                    </td>
                                    <td><strong>Diámetro</strong>
                                    </td>
                                    <td><strong>Piezas</strong>
                                    </td>
                                  </tr>

                                </thead>
                                <tbody>
                                    <tr>
                                        <td rowspan="6">Redondo</td>
                                        <td>1.315"</td>
                                        <td>127</td>
                                    </tr>
                                    <tr>
                                        <td>1 1/2"</td>
                                        <td>77</td>
                                    </tr>
                                    <tr>
                                        <td>1 5/8"</td>
                                        <td>68</td>
                                    </tr>
                                    <tr>
                                        <td>1 7/8"</td>
                                        <td>68</td>
                                    </tr>
                                    <tr>
                                        <td>2 3/8"</td>
                                        <td>46</td>
                                    </tr>
                                    <tr>
                                        <td>2 7/8"</td>
                                        <td>28</td>
                                    </tr>
                                </tbody>
                              </table>
                            </div>
                            
                            
                          </div>


                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          TIPOS DE TUBO GALVANIZADO
                        </button>
                      </h2>
                      <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <div class="row">

                            <div class="col-lg-6">
                              <table class="table">
                                <thead >

                                  <tr>
                                    <td><strong>Diámetro</strong>
                                    </td>
                                    <td><strong>Calibre</strong>
                                    </td>
                                    <td><strong>Kg / Pza</strong>
                                    </td>
                                    <td><strong>Largo</strong>
                                    </td>
                                  </tr>

                                </thead>
                                <tbody>
                                    <tr>
                                        <td rowspan="4">
                                            <p>1 7/8”</p>
                                        </td>
                                        <td>
                                            <p>16</p>
                                        </td>
                                        <td>
                                            <p>10.74</p>
                                        </td>
                                        <td>
                                            <p>6.00</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>18</p>
                                        </td>
                                        <td>
                                            <p>8.63</p>
                                        </td>
                                        <td>
                                            <p>6.00</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>20</p>
                                        </td>
                                        <td>
                                            <p>6.52</p>
                                        </td>
                                        <td>
                                            <p>6.00</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>22</p>
                                        </td>
                                        <td>
                                            <p>5.46</p>
                                        </td>
                                        <td>
                                            <p>6.00</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="3">
                                            <p>2 3/8”</p>
                                        </td>
                                        <td>
                                            <p>16</p>
                                        </td>
                                        <td>
                                            <p>13.66</p>
                                        </td>
                                        <td>
                                            <p>6.00</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>18</p>
                                        </td>
                                        <td>
                                            <p>10.99</p>
                                        </td>
                                        <td>
                                            <p>6.00</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>20</p>
                                        </td>
                                        <td>
                                            <p>8.32</p>
                                        </td>
                                        <td>
                                            <p>6.00</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="3">
                                            <p>2 7/8”</p>
                                        </td>
                                        <td>
                                            <p>16</p>
                                        </td>
                                        <td>
                                            <p>16.64</p>
                                        </td>
                                        <td>
                                            <p>6.00</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>18</p>
                                        </td>
                                        <td>
                                            <p>13.35</p>
                                        </td>
                                        <td>
                                            <p>6.00</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>20</p>
                                        </td>
                                        <td>
                                            <p>10.06</p>
                                        </td>
                                        <td>
                                            <p>6.00</p>
                                        </td>
                                    </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="col-lg-6">
                              <table class="table">
                                <thead >

                                  <tr>
                                    <td><strong>Diámetro</strong>
                                    </td>
                                    <td><strong>Calibre</strong>
                                    </td>
                                    <td><strong>Kg / Pza</strong>
                                    </td>
                                    <td><strong>Largo</strong>
                                    </td>
                                  </tr>

                                </thead>
                                <tbody>
                                    <tr>
                                        <td rowspan="4">1.315”</td>
                                        <td>16</td>
                                        <td>7.39</td>
                                        <td>6.00</td>
                                    </tr>
                                    <tr>
                                        <td>18</td>
                                        <td>6.00</td>
                                        <td>6.00</td>
                                    </tr>
                                    <tr>
                                        <td>20</td>
                                        <td>4.53</td>
                                        <td>6.00</td>
                                    </tr>
                                    <tr>
                                        <td>22</td>
                                        <td>3.79</td>
                                        <td>6.00</td>
                                    </tr>
                                    <tr>
                                        <td rowspan="4">1 ½“</td>
                                        <td>16</td>
                                        <td>8.44</td>
                                        <td>6.00</td>
                                    </tr>
                                    <tr>
                                        <td>18</td>
                                        <td>6.85</td>
                                        <td>6.00</td>
                                    </tr>
                                </tbody>
                              </table>
                            </div>
                            
                           

                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <!--  -->
                </div>
              </div>
            </div>


          </div>
          <div class="col-lg-3">
            <div class="card text-start">
              <div class="card-body">
                <router-link to="/mueblero">
                  <img src="../assets/img-difmerc.jpg" class="img-responsive" alt="Image" style="width: 100%;">
                </router-link>

                <div class="card text-start " style="background:rgb(10, 10, 80);color: #fff;margin-top: 10px;">
                  <div class="card-header">
                    <h5 class="card-title">PRODUCTOS</h5>
                  </div>
                  <div class="card-body">
                    <div class="btn-group-vertical " role="group" aria-label="Vertical button group">
                      <router-link to="/rolada" class="boton">Tubería Rolada en Fría</router-link>
                           <router-link to="/galvanizado" class="boton">Tubo Galvanizado</router-link>
                           <router-link to="/tubog" class="boton">Tubo G-90</router-link>
                          <router-link to="/cedula" class="boton">Tubería Rolado en caliente & PTR</router-link>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <FooterV/>
</template>
<script>
import HeaderV from '@/components/HeaderV.vue'

import FooterV from '@/components/FooterV.vue'
export default {
    name: 'GalvanizadoV',
  props: {
  }  ,components: {
    HeaderV,
    FooterV
  }
}
</script>
<style>
/* .ventaja {
  line-height: 0px;
} */

.boton {
  background: rgb(10, 10, 80);
  color: #fff;
}

thead{
 background-color: rgb(176, 0, 0); 
 color: #fff;
}
.accordion-button{
  background: rgb(56, 56, 56);
  color: #fff;
}
</style>