<template lang="">
    <div class="container">
        <div class="card text-start">
            <div class="card-body" id="imgbackground">
                <div id="todo">
                    <img id="imgfondo" src="../assets/tubos_rojo.png" alt="">
                    <div id="letras">
                        <img id="imgiso" src="../assets/iso9001.png" alt="">
                        <div id="puraletra">
                            <p>ALTA TECNOLOGÍA</p>
                            <p>transformada</p>
                            <p>EN PRODUCTOS <br> DE CALIDAD</p>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ImagenV',
  props: {
  }
}
</script>
<style>
#imgbackground{
    background-color: black;
}
#imgiso {
    width: 200px;
}

#imgfondo {
    position: relative;
    filter: brightness(30%) blur(3px);
    width: 100%;
    height: 100%;
}

#letras {
    position: absolute;
    text-align: center;
    top: 10px;
    left: 7%;
    color: #fff;
    font-size: 50px;
    margin-top: 50px;
}

#todo {
    width: 600px;
    height: 800px;
}

#puraletra {
    margin-top: 100px;
    font-family: 'Lato', sans-serif;font-weight: 900;
}
</style>