<template lang="">
    <HeaderV/>
   <div class="container">
    <div class="card text-start">
        <h4 class="card-header"> PRODUCTOS </h4>
        <div class="card-body">
      
          <div class="row text-center" >
         
              <router-link to="/rolada" class="col-lg-6">
                 <img  src="../assets/img-produc-1.jpg" alt="img produc 1" style="width: 80%; margin: 10px;">
              </router-link>
              <router-link to="/galvanizado" class="col-lg-6">
                 <img src="../assets/img-produc-2.jpg" alt="img produc 2" style="width: 80%; margin: 10px;">
                </router-link>
              <router-link to="/tubog" class="col-lg-6">
               <img src="../assets/img-produc-3.jpg" alt="img produc 3" style="width: 80%; margin: 10px;">
                </router-link>
                  
                <router-link to="/cedula" class="col-lg-6">
                  <img src="../assets/img-produc-4.jpg" alt="img produc 4" style="width: 80%; margin: 10px;">
             </router-link>
          </div>
      
        </div>
      </div>
</div>
<FooterV/>
</template>
<script>
import HeaderV from '@/components/HeaderV.vue'

import FooterV from '@/components/FooterV.vue'

export default {
    name: 'ProductosV',
  props: {
  },
  components: {
    HeaderV,
    FooterV
  }
}
</script>
<style lang="">
    
</style>