<template lang="">
      <HeaderV/>
      <div class="container">
        <div class="card text-start">
            <div class="card-header">
                <h4 class="card-title">
                    BOLSA DE TRABAJO
                </h4>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-12">
                        <p class="card-text">
                            En Flexometal, nos encontramos en una búsqueda constante de talento, es por esto que estamos
                            buscando gente que se adhiera al equipo de trabajo.
                            Nuestras vacantes son las siguientes:
                        </p>

                        <!-- Carousel -->
                        <div id="demo" class="carousel slide" data-bs-ride="carousel">

                            <!-- Indicators/dots -->
                            <div class="row" style="margin-bottom: 10px;">
                                <div class="col-lg-6" style="display: flex; align-items: center; justify-content: center;">
                                    <button data-bs-target="#demo" data-bs-slide-to="0"
                                        class="btn btn-secondary active">Auditor de Calidad</button>
                                </div>
                                <div class="col-lg-6" style="display: flex; align-items: center; justify-content: center;">
                                    <button data-bs-target="#demo" data-bs-slide-to="1"
                                        class="btn btn-secondary">Auxiliar de TI</button>
                                </div>


                            </div>

                            <!-- The slideshow/carousel -->
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <div class="row">
                                        <div class="card text-start">
                                            <div class="card-body">
                                                <h4 class="card-title">AUDITOR DE CALIDAD</h4>
                                                <h5 class="card-title">OBJETIVO:</h5>
                                                <p>Cumplir con las auditorias del proceso y producto en sus diferentes etapas de fabricación, generación de evidencia de las inspecciones realizadas en el producto y notificación de fallas y defectos detectados en el proceso y producto.</p>
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        <h6>FUNCIONES:</h6>
                                                        <div class="container">
                                                            <p class="descri">
                                                                <i class="fa-solid fa-share"></i>
                                                                Inspección, medición, validación y aprobación de producto en las diferentes etapas de manufactura.
                                                            </p>
                                                            <p class="descri">
                                                                <i class="fa-solid fa-share"></i>
                                                                Registrar las NO conformidades y observaciones encontradas en las inspecciones y noticiarlas a los Gerentes o Jefes de área correspondientes para la toma de decisiones
                                                            </p>
                                                            <p class="descri">
                                                                <i class="fa-solid fa-share"></i>
                                                                Identificación de producto no conforme.
                                                            </p>
                                                            <p class="descri">
                                                                <i class="fa-solid fa-share"></i>
                                                                Proveer criterios de aceptación / rechazo a personal de manufactura.
                                                            </p>
                                                            <p class="descri">
                                                                <i class="fa-solid fa-share"></i>
                                                                Identificación, notificación de fallas reportadas en la materia prima.
                                                            </p>
                                                        </div>
                                                        <h6>OFRECEMOS:</h6>
                                                        <div class="container">
                                                            <p class="descri">
                                                                <i class="fa-solid fa-share"></i>
                                                                6000 Libres
                                                            </p>
                                                            <p class="descri">
                                                                <i class="fa-solid fa-share"></i>
                                                                Prestaciones de ley.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <h6>REQUISITOS:</h6>
                                                        <div class="container">
                                                            <p class="descri">
                                                                <i class="fa-solid fa-share"></i>
                                                                Edad de 25 a 40 años.
                                                            </p>
                                                            <p class="descri">
                                                                <i class="fa-solid fa-share"></i>
                                                                Nivel secundaria, preferentemente preparatoria y/o Técnico superior Universitario.
                                                            </p>
                                                            <p class="descri">
                                                                <i class="fa-solid fa-share"></i>
                                                                Conocimiento en Inspecciones de Calidad, preferentemente conocimiento en ISO 9001-2008, equipos de medición y criterios de aceptación, correcto llenado de formatos. 1 año en puestos similares.
                                                            </p>
                                                            <p class="descri">
                                                                <i class="fa-solid fa-share"></i>
                                                                Lugar de trabajo carretera a Morelia #540 Col. San Agustín en frente de fraccionamiento Bonanza.
                                                            </p>
                                                            <p class="descri">
                                                                <i class="fa-solid fa-share"></i>
                                                                Disponibilidad de horarios
                                                            </p>
                                                        </div>
                                                        <div class="col-lg-12 " style="padding: 20px 70px 20px 70px ;color: #fff;background:rgb(10, 10, 80) ;">
                                                            <h6>DATOS DE CONTACTO:</h6>
                                                            <p class="descri" >Departamento de Capital Humano.
                                                                
                                                            </p>
                                                            <p class="descri" >Tel: 01 33 3188 2469 / 01 33 3271 5705 / 06</p>
                                                          </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="row">
                                        <div class="card text-start">
                                            <div class="card-body">
                                                <h4 class="card-title">AUXILIAR DE TI</h4>
                                                <h5 class="card-title">OBJETIVO:</h5>
                                                <p>Monitorear el desempeño de las redes, dar mantenimiento a la infraestructura de TI. Desarrollo, administración y actualización de comunicaciones y circuito cerrado de la empresa.</p>
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        <h6>FUNCIONES:</h6>
                                                        <div class="container">
                                                            <p class="descri">
                                                                <i class="fa-solid fa-share"></i>
                                                                Dar mantenimiento a la infraestructura de TI de la compañía (PCs, Servidores, Monitores, Laptops, Conmutador, pantallas, impresoras, Switches, Sistema de circuito cerrado).
Monitoreo del desempeño de las redes de TI.
                                                            </p>
                                                            <p class="descri">
                                                                <i class="fa-solid fa-share"></i>
                                                                Configuración de equipo de cómputo para personal de nuevo ingreso.
                                                            </p>
                                                            <p class="descri">
                                                                <i class="fa-solid fa-share"></i>
                                                                Realizar Cartas de resguardo de equipo de cómputo asignado al personal.
                                                            </p>
                                                            <p class="descri">
                                                                <i class="fa-solid fa-share"></i>
                                                                Brindar soporte y asesoría a todo aquel empleado que lo requiera.
                                                            </p>
                                                        </div>
                                                        <h6>OFRECEMOS:</h6>
                                                        <div class="container">
                                                            <p class="descri">
                                                                <i class="fa-solid fa-share"></i>
                                                                6000 Libres
                                                            </p>
                                                            <p class="descri">
                                                                <i class="fa-solid fa-share"></i>
                                                                Prestaciones de ley.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <h6>REQUISITOS:</h6>
                                                        <div class="container">
                                                            <p class="descri">
                                                                <i class="fa-solid fa-share"></i>
                                                                Carrera técnica y/o licenciatura a tecnologías de la información, sistemas o a fin.
Inglés intermedio.
                                                            </p>
                                                            <p class="descri">
                                                                <i class="fa-solid fa-share"></i>
                                                                Experiencia no necesaria, recién egresados.
                                                            </p>
                                                            <p class="descri">
                                                                <i class="fa-solid fa-share"></i>
                                                                Lugar de trabajo carretera a Morelia #540 Col. San Agustín en frente de fraccionamiento Bonanza.
                                                            </p>
                                                            <p class="descri">
                                                                <i class="fa-solid fa-share"></i>
                                                                Disponibilidad de para cubrir horario de miércoles a domingo de 8:00 a 6:00 descansa Lunes y Martes
                                                            </p>
                                                        </div>
                                                        <div class="col-lg-12 " style="padding: 20px 70px 20px 70px ;color: #fff;background:rgb(10, 10, 80) ;">
                                                            <h6>DATOS DE CONTACTO:</h6>
                                                            <p class="descri" >Departamento de Capital Humano.
                                                                
                                                            </p>
                                                            <p class="descri" >Tel: 01 33 3188 2469 / 01 33 3271 5705 / 06</p>
                                                          </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <FooterV/>
</template>
<script>
import HeaderV from '@/components/HeaderV.vue'

import FooterV from '@/components/FooterV.vue'
export default {
    name: 'BolsaTrabajoV',
  props: {
  },  components: {
    HeaderV,
    FooterV
  }
}
</script>
<style lang="">
    
</style>