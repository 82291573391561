<template lang="">
    <HeaderV/>
   <div class="container">
        <div class="card text-start">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-9">
                        <div class="card text-start">
                            <div class="card-header">
                                <h4 class="card-title">NOTICIAS </h4>
                            </div>

                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <img src="../assets/img-noticia-01.jpg" alt="img noticia 01" title="Noticias"
                                            style="width: 100%;">
                                    </div>
                                    <div class="col-lg-12">
                                        <p class="card-text" style="margin-top: 10px;">En Flexometal buscamos ofrecerle
                                            a nuestros clientes una mayor gama de opciones en tubería de acero, es por
                                            esto que es grato para nosotros informarles que estamos iniciando un nuevo
                                            proyecto adquiriendo nuevos rodillos para incluir los siguientes productos a
                                            nuestro portafolio:</p>

                                        <div class="container">
                                            <p class="card-text">
                                                * Cuadrado de 7/8” x 7/8”
                                            </p>
                                            <p class="card-text">
                                                * Rectangular 1” x 1 ½”
                                            </p>
                                        </div>

                                        <p class="card-text" style="padding-top: 20px;">
                                            En cualquier presentación de lámina (Rolado Frío, Galvanizado, PTR)
                                        </p>
                                        <p class="card-text" style="padding-top: 10px;">
                                            Haciendo mención a la sección de material PTR es un gusto para Flexometal hacer promoción a sus medidas de PTR añadidas recientemente:
                                        </p>
                                        <div class="container">
                                            <p class="card-text">
                                                * 3” x 3
                                            </p>
                                            <p class="card-text">
                                                * 2 ½” x 2 ½”
                                            </p>
                                        </div>
                                        <p class="card-text">
                                            Así como también:
                                        </p>
                                        <div class="container">
                                            <p class="card-text">
                                                * 3” x 1 ½”
                                            </p>
                                            <p class="card-text">
                                                * 1 ½” x 1 ½”
                                            </p>
                                        </div>
                                        <p class="card-text" style="padding-top: 20px;">
                                            Adicional a estos nuevos productos estamos ampliando la gama de calibres en los productos de Rolado Frío añadiendo el calibre 14, es decir, al día de hoy manejamos calibres 14 en material galvanizado y en la sección de PTR, esto con el fin de ofrecer nuevos productos a nuestros clientes.
                                        </p>
                                        <p class="card-text">
                                            Por lo que te invitamos a comunicarte con nosotros y solicitar cotización de estos materiales.
                                        </p>

                                        <!-- <a href="">
                                            <p class="card-text"> <span class="text-danger">Continuar leyendo</span>
                                            </p>
                                        </a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="card text-start">
                            <div class="card-body">
                                <div class="col-lg-12 text-center">
                                    <router-link to="/empresa" ><img
                                            src="../assets/img-ir-tendencias.jpg" alt="img ir tendencias"
                                            style="width: 80%;">
                                        </router-link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <FooterV/>
</template>
<script>
import HeaderV from '@/components/HeaderV.vue'

import FooterV from '@/components/FooterV.vue'
export default {
    name: 'NoticiasV',
  props: {
  },
  components: {
    HeaderV,
    FooterV
  }
}
</script>
<style lang="">
    
</style>