<template>
    <div class="home">




        <div class="container">
            <!-- <h1>
            <%= title %>
        </h1>
        <p>Welcome to <%= title %>
        </p> -->
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
                    <a href="/">
                        <img src="../assets/Logo-FlexoMetal.png" class="rounded float-start img-fluid" alt="..."
                            style="width: 60%; padding-bottom: 10px;">
                    </a>

                </div>
                <div class="col-lg-6 col-md-6 col-sm-3 col-xs-3"
                    style="display: flex; align-items: center;justify-content: end;">
                    <div class="col-lg-2 col-md-2 col-sm-1 col-xs-1">

                        <router-link to="/certificado">
                            <img src="../assets/iso9001.png" alt="" style="width: 80%;">
                        </router-link>
                    </div>


                </div>
            </div>


            <!-- <div class="btn-group" style="width: 100%;height: 50px;margin-bottom: 10px;">
            <a href="/" class="btn boton-nav " aria-current="page">
                <h5>Inicio</h5>
            </a>
            <a href="/empresa" class="btn boton-nav">
                <h5>Empresa</h5>
            </a>
            <a href="/productos" class="btn boton-nav">
                <h5>Productos</h5>
            </a>
            <a href="/capacidad" class="btn boton-nav">
                <h5>Capacidad instalada</h5>
            </a>
            <a href="/noticias" class="btn boton-nav">
                <h5>Noticias</h5>
            </a>
            <a href="/contacto" class="btn boton-nav">
                <h5>Contacto</h5>
            </a>
        </div> -->
        
                    <nav class="navbar navbar-expand-lg bg-body-tertiary bg-light rounded-3"
                        style="margin-bottom: 20px;">
                        <div class="container-fluid">
                            <a href="/" class="navbar-brand"><i class="fa-solid fa-house"></i></a>
                            <button class="navbar-toggler " type="button" data-bs-toggle="collapse"
                                data-bs-target="#menuNav">
                                <span class="navbar-toggler-icon"></span>
                            </button>

                            <div id="menuNav" class="collapse navbar-collapse">




                                <ul class="navbar-nav ms-3">



                                    <li class="nav-item">
                                        <router-link to="/" class="nav-link "><strong>Inicio</strong></router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/empresa" class="nav-link"><strong>Empresa</strong></router-link>
                                    </li>
                                    <li class="nav-item dropdown ">

                                        <router-link to="/productos" class="nav-link dropdown-toggle " role="button"
                                            data-bs-toggle="dropdown"><strong>Productos</strong></router-link>
                                        <ul class="dropdown-menu" style="color:#fff;background-color: #bfb0b0;">
                                            <li><router-link to="/productos" class="nav-link">Nuestros
                                                    productos</router-link></li>
                                            <li><router-link to="/rolada" class="nav-link">Tubería Rolada en
                                                    Fría</router-link></li>
                                            <li><router-link to="/galvanizado" class="nav-link">Tubo
                                                    Galvanizado</router-link></li>
                                            <li><router-link to="/tubog" class="nav-link">Tubo G-90</router-link></li>
                                            <li><router-link to="/cedula" class="nav-link">Tubería Rolado en caliente &
                                                    PTR</router-link></li>




                                        </ul>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/capacidad"
                                            class="nav-link"><strong>Capacidad</strong></router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/noticias" class="nav-link"><strong>Noticias</strong></router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/contacto" class="nav-link"><strong>Contacto</strong></router-link>
                                    </li>


                                </ul>



                                
                            </div>
                        </div>
                    </nav>
               
            <!-- <nav class="navbar navbar-expand-lg navbar-expand-md navbar-expand-sm navbar-light bg-light">
            <div class="container-fluid">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="/">Inicio</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/empresa">Empresa</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/productos">Productos</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/capacidad">Capacidad instalada</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/noticias">Noticias</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/contacto">Contacto</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav> -->


        </div>





        <HomeV />
        <FooterV />

    </div>
</template>



<style>
body {
    border-top: 5px solid #000000;
    background: #a21400 url("../assets/bg-metal.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100%;


}

/* .boton-nav {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: #a8adb1;
            text-align: center;
            text-decoration: none;
            font-size: large;
            font: bolder;
        }

        .boton-nav:hover {
            transition: transform 0.5s;
            border-color: #505153;
            background-color: #505153;


        }

        .boton-nav:click {
            border-color: #505153;
            background-color: #505153;
        } */

li a {
    font-size: 15px;
    margin-left: 30px;
    /* border: solid 1px black; */
}
</style>


<script>
// @ is an alias to /src
import HomeV from './HomeV.vue'
import FooterV from '@/components/FooterV.vue'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"


export default {
    name: 'HomeView',
    components: {
        FooterV,
        HomeV,

    }
}
</script>
