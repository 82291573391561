<template lang="">
      <HeaderV/>
  <div class="container">
        <div class="card text-start">
            <div class="card-header">
                <h3 class="card-title">CERTIFICADOS BAJO EL SGC ISO 9001; 2015</h3>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-12">
                        <p class="card-text">Como un valor añadido hemos realizado una <span class="text-danger">serie
                                de mejoras</span> en nuestra planta para asegurar la <span class="text-danger">calidad
                                de nuestros productos</span>.</p>
                    </div>
                    
                    <div class="col-lg-12 row" style="margin-top: 20px;">
                        <div class="col-lg-5 text-center" >
                            <img src="../assets/certificado.jpg" alt="certificado" style="width: 100%;">
                        </div>
                        <div class="col-lg-7" >
                            <p class="card-text">Mejoras físicas, basadas en equipo de <span class="text-danger">alta
                                    tecnología</span> lo que nos permiten
                                alcanzar los altos estándares requeridos por nuestros clientes.</p>
    
                            <p class="card-text"><span class="text-danger">Mejoras documentales</span>. Implementación de
                                los estándares de calidad
                                establecidos en la certificación <span class="text-danger">ISO 9001:2015</span> lo cual nos
                                da el control necesario para
                                <span class="text-danger">eliminar</span> las posibles causas de <span
                                    class="text-danger">imperfecciones</span> en nuestros productos.
                            </p>
                        </div>
                    </div>
                    

                </div>

            </div>
        </div>
    </div>
    <FooterV/>
</template>
<script>
import HeaderV from '@/components/HeaderV.vue'

import FooterV from '@/components/FooterV.vue'
export default {
    name: 'CertificadoV',
  props: {
  },
  components: {
    HeaderV,
    FooterV
  }
}
</script>
<style lang="">
    
</style>