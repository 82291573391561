<template lang="">
  <div class="container">
        <div class="card text-start">
            <div class="card-body">
                <div class="row">
                    <form action="/enviarContacto" method="POST">
                        <div class="row ">

                            <div class="form-floating col-lg-6">
                                <input type="text" class="form-control" id="cve_cte" placeholder="clave de cliente">
                                <label for="cve_cte" style="margin-left: 10px;">Clave de Cliente</label>
                            </div>
                            <div class="form-floating mb-3 col-lg-6">
                                <input type="password" class="form-control" id="contraseña"
                                    placeholder="name@example.com">
                                <label for="contraseña" style="margin-left: 10px;"
                                    aria-required="true">Contraseña</label>
                            </div>
                            <div class="col-lg-12 " style="margin-top: 10px;">
                                <button type="button" class="btn btn-primary "
                                    onclick="consultarCliente()">Cosultar</button>
                            </div>
                        </div>

                    </form>
                </div>

                <div class="container" id="div_tblClientes" style="display: none;margin-top: 30px;">
                    <table class="table" id="tblClientes" style="text-align: center;font-size: 15px;">
                        <thead class="table-dark">
                            <tr>
                                <th>Folio</th>
                                <th>Uuid</th>
                                <th>Fecha Factura</th>
                                <th>Fecha Timbre</th>
                                <th>xml</th>
                                <th>pdf</th>
                            </tr>
                        </thead>
                        <tbody>

                        </tbody>
                    </table>
                </div>

            </div>
        </div>
        <div class="modal fade" id="modal_loader">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Obteniendo Documento</h4>
                    </div>
                    <div class="modal-body">
                        <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ClientesV',
  props: {
  } 
}
</script>
<style lang="">
    
</style>