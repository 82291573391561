import { createRouter,  createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
//import EmpresaV from '../views/EmpresaV.vue'
import AutomotrizV from '../views/AutomotrizV.vue'
import AvisoPrivacidadV from '../views/AvisoPrivacidadV.vue'
import BolsaTrabajoV from '../views/BolsaTrabajoV.vue'
import CapacidadV from '../views/CapacidadV.vue'
import CedulaV from '../views/CedulaV.vue'
import CerqueroV from '../views/CerqueroV.vue'
import CertificadoV from '../views/CertificadoV.vue'
import ClientesV from '../views/ClientesV.vue'
import ContactoV from '../views/ContactoV.vue'
import CotizacionV from '../views/CotizacionV.vue'
import ErrorV from '../views/ErrorV.vue'
import GalvanizadoV from '../views/GalvanizadoV.vue'
import ImagenV from '../views/ImagenV.vue'
import IndustriaV from '../views/IndustriaV.vue'
import MacroTunelV from '../views/MacroTunelV.vue'
import MuebleroV from '../views/MuebleroV.vue'
import NoticiasV from '../views/NoticiasV.vue'
import PoliticasV from '../views/PoliticasV.vue'
import PreguntasFrecuentesV from '../views/PreguntasFrecuentesV.vue'
import ProductosV from '../views/ProductosV.vue'
import RoladaV from '../views/RoladaV.vue'
import TubogV from '../views/TubogV.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },{
    path: '/empresa',
    name: 'empresa',
    component: () => import(/* webpackChunkName: "about" */ '../views/EmpresaV.vue')
  },{
    path: '/automotriz',
    name: 'automotriz',
    component: AutomotrizV
  },{
    path: '/avisoPrivacidad',
    name: 'avisoPrivacidad',
    component: AvisoPrivacidadV
  },{
    path: '/bolsaTrabajo',
    name: 'bolsaTrabajo',
    component: BolsaTrabajoV
  },{
    path: '/capacidad',
    name: 'capacidad',
    component: CapacidadV
  },{
    path: '/cedula',
    name: 'cedula',
    component: CedulaV
  },{
    path: '/cerquero',
    name: 'cerquero',
    component: CerqueroV
  },{
    path: '/certificado',
    name: 'certificado',
    component: CertificadoV
  },{
    path: '/clientes',
    name: 'clientes',
    component: ClientesV
  },{
    path: '/contacto',
    name: 'contacto',
    component: ContactoV
  },{
    path: '/cotizacion',
    name: 'cotizacion',
    component: CotizacionV
  },{
    path: '/error',
    name: 'error',
    component: ErrorV
  },{
    path: '/galvanizado',
    name: 'galvanizado',
    component: GalvanizadoV
  },{
    path: '/imagen',
    name: 'imagen',
    component: ImagenV
  },{
    path: '/industria',
    name: 'industria',
    component: IndustriaV
  },{
    path: '/macrotunel',
    name: 'macrotunel',
    component: MacroTunelV
  },{
    path: '/mueblero',
    name: 'mueblero',
    component: MuebleroV
  },{
    path: '/noticias',
    name: 'noticia',
    component: NoticiasV
  },{
    path: '/politicas',
    name: 'politicas',
    component: PoliticasV
  },{
    path: '/preguntasFrecuentes',
    name: 'preguntasFrecuentes',
    component: PreguntasFrecuentesV
  },{
    path: '/productos',
    name: 'productos',
    component: ProductosV
  },{
    path: '/rolada',
    name: 'rolada',
    component: RoladaV
  },{
    path: '/tubog',
    name: 'tubog',
    component: TubogV
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
