<template lang="">
  <HeaderV/>
    <div class="container">
        <div class="card text-start">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-9">
                        <div class="card text-start">
                            <div class="card-header">
                                <h4>CAPACIDAD INSTALADA</h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <h5 class="card-title">CORTE DE CINTAS</h5>
                                        <p class="card-text">En las intalaciones de la empresa se cuenta con <span class="text-danger">2 Slitter</span> con capacidad para cortar <span class="text-danger">3500 Ton</span>/Mes de capacidad. </p>

                                        <h5 class="card-title">FORMATO DE TUBO</h5>
                                        <p class="card-text">3 Molinos con <span class="text-danger">3000 Ton</span>/Mes de capacidad
                                            1 Perfiladora para fabricación de Angulo Camero Cal. <span class="text-danger">14 a 1.96</span> metros de largo con capacidad para fabricar <span class="text-danger">100 toneladas</span> al mes.</p>

                                    </div>
                                    <div class="col-lg-6">
                                        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                                            <div class="carousel-inner">
                                              <div class="carousel-item active">
                                                <img src="../assets/cap-inst/cap-inst-01.jpg" class="d-block w-100" alt="">
                                              </div>
                                              <div class="carousel-item ">
                                                <img src="../assets/cap-inst/cap-inst-02.jpg" class="d-block w-100" alt="">
                                              </div>
                                              <div class="carousel-item" >
                                                <img src="../assets/cap-inst/cap-inst-03.jpg" class="d-block w-100" alt="">
                                              </div>
                                              <div class="carousel-item" >
                                                <img src="../assets/cap-inst/cap-inst-04.jpg" class="d-block w-100" alt="">
                                              </div>
                                              <div class="carousel-item" >
                                                <img src="../assets/cap-inst/cap-inst-05.jpg" class="d-block w-100" alt="">
                                              </div>
                                              <div class="carousel-item" >
                                                <img src="../assets/cap-inst/cap-inst-06.jpg" class="d-block w-100" alt="">
                                              </div>
                                              <div class="carousel-item" >
                                                <img src="../assets/cap-inst/cap-inst-07.jpg" class="d-block w-100" alt="">
                                              </div>
                                              <div class="carousel-item" >
                                                <img src="../assets/cap-inst/cap-inst-08.jpg" class="d-block w-100" alt="">
                                              </div>
                                              <div class="carousel-item" >
                                                <img src="../assets/cap-inst/cap-inst-09.jpg" class="d-block w-100" alt="">
                                              </div>
                                              <div class="carousel-item" >
                                                <img src="../assets/cap-inst/cap-inst-10.jpg" class="d-block w-100" alt="">
                                              </div>
                                              <div class="carousel-item" >
                                                <img src="../assets/cap-inst/cap-inst-11.jpg" class="d-block w-100" alt="">
                                              </div>
                                            </div>
                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
                                              data-bs-slide="prev">
                                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                              <span class="visually-hidden">Previous</span>
                                            </button>
                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
                                              data-bs-slide="next">
                                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                              <span class="visually-hidden">Next</span>
                                            </button>
                                          </div>
                                    </div>
                                    <div class="col-lg-12" style="margin-top: 20px;">
                                        <h5 class="card-title" >ALMACENAMIENTO</h5>
                                        <p class="card-text">La capacidad de almacenaniento que se cuenta en la empresa es de <span class="text-danger">3500 m2</span> en donde se almacena el producto terminado, rollos de acero así como la cinta utilizada en nuestro proceso de <span class="text-danger">fabricación</span>. </p>

                                       

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="card text-start">
                            <div class="card-body">
                                <div class="col-lg-12">
                                  <router-link to="/certificado" >
                          <img src="../assets/img-ir-certificaciones.jpg" alt="img ir certificaciones" style="width: 100%;"> </router-link>
                                </div>
                                <div class="col-lg-12">
                                    
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <FooterV/>
</template>
<script>
import HeaderV from '@/components/HeaderV.vue'

import FooterV from '@/components/FooterV.vue'
export default {
    name: 'CapacidadV',
  props: {
  },  components: {
    HeaderV,
    FooterV
  }
}
</script>
<style lang="">
    
</style>