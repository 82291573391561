<template lang="">
      <HeaderV/>
  <div class="container">
        <div class="card text-start">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-9">
                        <div class="card text-start">
                            <div class="card-header">
                                <h4 class="card-title">CERQUERO</h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <p class="card-text">
                                            En este mercado se comercializa <span class="text-danger">tubería
                                                galvanizada</span> , principalmente es utilizado para la instalación de <span
                                                class="text-danger">mallas ciclónicas</span>, los usos más
                                            representativos en los que se utiliza el tubo fabricado en <span
                                                class="text-danger">Flexometal</span> es utilizado para delimitar <span
                                                class="text-danger">áreas de espacios</span>, tales como:
                                        </p>
                                    </div>
                                    <div class="col-lg-6">
                                        <p class="card-text ventaja"><i
                                                class="fa-regular fa-arrow-right text-danger"></i> Canchas de tenis</p>
                                        <p class="card-text ventaja"><i
                                                class="fa-regular fa-arrow-right text-danger"></i> Canchas de futbol</p>
                                        <p class="card-text ventaja"><i
                                                class="fa-regular fa-arrow-right text-danger"></i> Patios</p>
                                        <p class="card-text ventaja"><i
                                                class="fa-regular fa-arrow-right text-danger"></i> Techos de casas
                                            particulares</p>
                                        <p class="card-text ventaja"><i
                                                class="fa-regular fa-arrow-right text-danger"></i> Techos de empresas.
                                        </p>
                                        <p class="card-text">
                                            Se manejan diversos <span class="text-danger">diámetros</span> de tubería a dos largos estándares que son: <span class="text-danger">4.8 metros y 6.00 metros</span> de largo.
                                        </p>
                                        <p class="card-text">

                                        </p>
                                    </div>
                                    <div class="col-lg-12">
                                        <section class="galeria">
                                            <a>
                                                <img src="../assets/img-cerq/img-cerq-01.jpg" alt="">
                                            </a>
                                            <a>
                                                <img src="../assets/img-cerq/img-cerq-02.jpg" alt="">
                                            </a>
                                            <a>
                                                <img src="../assets/img-cerq/img-cerq-03.jpg" alt="">
                                            </a>
                                            <a>
                                                <img src="../assets/img-cerq/img-cerq-04.jpg" alt="">
                                            </a>
                                            <a>
                                                <img src="../assets/img-cerq/img-cerq-05.jpg" alt="">
                                            </a>
                                            <a>
                                                <img src="../assets/img-cerq/img-cerq-06.jpg" alt="">
                                            </a>
                                        </section>

                                        <article class="light-box" id="image1">
                                            <a href="#image6" class="next"><i class="fa-solid fa-arrow-left"></i></a>
                                            <img src="../assets/img-cerq/img-cerq-01.jpg" alt="">
                                            <a href="#image2" class="next"><i class="fa-solid fa-arrow-right"></i></a>
                                            <a href="#" class="close"><i class="fa-solid fa-xmark-large"></i></a>
                                        </article>

                                        <article class="light-box" id="image2">
                                            <a href="#image1" class="next"><i class="fa-solid fa-arrow-left"></i></a>
                                            <img src="../assets/img-cerq/img-cerq-02.jpg" alt="">
                                            <a href="#image3" class="next"><i class="fa-solid fa-arrow-right"></i></a>
                                            <a href="#" class="close"><i class="fa-solid fa-xmark-large"></i></a>
                                        </article>

                                        <article class="light-box" id="image3">
                                            <a href="#image2" class="next"><i class="fa-solid fa-arrow-left"></i></a>
                                            <img src="../assets/img-cerq/img-cerq-03.jpg" alt="">
                                            <a href="#image4" class="next"><i class="fa-solid fa-arrow-right"></i></a>
                                            <a href="#" class="close"><i class="fa-solid fa-xmark-large"></i></a>
                                        </article>

                                        <article class="light-box" id="image4">
                                            <a href="#image3" class="next"><i class="fa-solid fa-arrow-left"></i></a>
                                            <img src="../assets/img-cerq/img-cerq-04.jpg" alt="">
                                            <a href="#image5" class="next"><i class="fa-solid fa-arrow-right"></i></a>
                                            <a href="#" class="close"><i class="fa-solid fa-xmark-large"></i></a>
                                        </article>

                                        <article class="light-box" id="image5">
                                            <a href="#image4" class="next"><i class="fa-solid fa-arrow-left"></i></a>
                                            <img src="../assets/img-cerq/img-cerq-05.jpg" alt="">
                                            <a href="#image6" class="next"><i class="fa-solid fa-arrow-right"></i></a>
                                            <a href="#" class="close"><i class="fa-solid fa-xmark-large"></i></a>
                                        </article>

                                        <article class="light-box" id="image6">
                                            <a href="#image5" class="next"><i class="fa-solid fa-arrow-left"></i></a>
                                            <img src="../assets/img-cerq/img-cerq-06.jpg" alt="">
                                            <a href="#image1" class="next"><i class="fa-solid fa-arrow-right"></i></a>
                                            <a href="#" class="close"><i class="fa-solid fa-xmark-large"></i></a>
                                        </article>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="card text-start " style="background:rgb(10, 10, 80);color: #fff;">
                            <div class="card-header">
                                <h5 class="card-title">MERCADOS</h5>
                            </div>
                            <div class="card-body">
                                <div class="btn-group-vertical " role="group" aria-label="Vertical button group">
                                    <router-link to="/industria" class="boton">Mueblero</router-link>
              <router-link to="/automotriz" class="boton">Automotriz</router-link>
              <router-link to="/macrotunel" class="boton">Macrotunel-Inventario-Casa Sombra</router-link>
              <router-link to="/cerquero" class="boton">Cerquero</router-link>
              <router-link to="/industria" class="boton">Industrial</router-link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <FooterV/>
</template>
<script>
import HeaderV from '@/components/HeaderV.vue'

import FooterV from '@/components/FooterV.vue'
export default {
    name: 'CerqueroV',
  props: {
  },  components: {
    HeaderV,
    FooterV
  }
}
</script>
<style>
.ventaja {
    line-height: 0px;
}

.boton {
    background: rgb(10, 10, 80);
    color: #fff;
}

.galeria {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    /*aqui da el tamaño del grid*/
    width: 100%;
    margin: auto;
    grid-gap: 10px;
    padding: 40px 0;
    overflow: hidden;
}

.galeria img {
    width: 100%;
    vertical-align: top;
    height: 200px;
    object-fit: cover;
    transition: transform 0.5s;
}

.galeria>a {
    display: block;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 6px rgba(0, 0, 0, .5);
}

.galeria a:hover img {
    filter: blur(2px);
    transform: rotate(10deg) scale(1.3);
}

.light-box {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
    transition: transform .3s ease-in-out;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0);
}

.light-box img {
    width: 40vw;
    max-height: 60vh;
}


.light-box:target {
    transform: scale(1);
}

.close {
    display: block;
    position: absolute;
    top: 100px;
    right: 400px;
    color: #f7f7f7;
    text-decoration: none;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    /* background: #851919; */
}

.next {
    display: block;
    /* background: #851919; */
    color: #fff;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-decoration: none;
    text-align: center;
}

@media screen and (max-width: 400px) {
    .galeria {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1f));
    }

    .close {
        top: 20px;
        right: 20px;
    }
}
</style>