import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

import VueAxios from 'vue-axios'
library.add(faPhone);


import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
createApp(App).use(router,VueAxios).component("font-awesome-icon", FontAwesomeIcon).mount('#app')
