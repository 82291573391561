<template lang="">
    <HeaderV/>
     <div class="container">
        <div class="card text-start">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-9">
                        <div class="card text-start">
                            <div class="card-header">
                                <h4 class="card-title">CONTACTO</h4>
                            </div>
                            <div class="card-body">
                                <form @submit.prevent="submitForm">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Nombre *</label>
                                       <input id="nombreContacto" type="text" v-model="nombreContacto" class="form-control"  placeholder="Juan Perez"/>
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Empresa</label>
                                      
                                            <input id="empresaContacto" type="text" v-model="empresaContacto" class="form-control"  placeholder="Empresa"/>
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Telefono</label>
    
                                <input id="telefonoContacto" type="text" v-model="telefonoContacto" class="form-control"  placeholder="Telefono"/>
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Email *</label>
                                  
                                            <input id="emailContacto" type="text" v-model="emailContacto" class="form-control"  placeholder="Email"/>


                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Ciudad</label>
                                     
                                            <input id="ciudadContacto" type="text" v-model="ciudadContacto" class="form-control"  placeholder="Ciudad"/>


                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Mensaje *</label>
                                        
                                        <textarea id="mensajeContacto" type="text" v-model="mensajeContacto" class="form-control"  placeholder="Escriba su mensaje"></textarea>

                                    
                                    
                                    </div>
                                    <div class="col-lg-12 text-center" style="margin-top: 10px;">
                                     
                                        <button type="submit" class="btn btn-primary btn-md">Enviar</button>

                                    </div>
                                </form>


                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="card text-start">
                            <div class="card-header">
                                <h5 class="card-title">UBICACIÓN</h5>
                            </div>
                            <div class="card-body">
                                <div class="row ">
                                    <div class="col-lg-12 text-center">

                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3736.1131474999424!2d-103.4754901858973!3d20.542550909576036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842f53fe5895b575%3A0x4c60171535099401!2sFlexometal%20S.A.%20de%20C.V.!5e0!3m2!1ses!2smx!4v1675117462716!5m2!1ses!2smx"
                                            width="100%" height="450" style="border:0;" allowfullscreen=""
                                            loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-2">
                                                <img src="../assets/embed-places-icon.png" alt="embed places icon"
                                                    width="37" height="37" style="margin-right: 10px;">
                                            </div>
                                            <div class="col-lg-10">
                                                <h6 style="margin-top: 10px;">DIRECCIÓN</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12" style="margin-top: 5px;">
                                        <p><i class="fa fa-fw fa-home"></i> Carretera a Morelia No. 540, Col. San
                                            Agustín Tlajomulco de Zúñiga, Jalisco, C.P. 45645</p>
                                    </div>
                                    <div class="col-lg-12">
                                        <p><i class="fa fa-fw fa-phone-square"></i> Tel: 01 33 36862490<br> <span
                                                style="margin-left: 52px;">01 33 31882469</span> </p>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <FooterV/>
</template>
<script>
import HeaderV from '@/components/HeaderV.vue'
import axios from 'axios'

import FooterV from '@/components/FooterV.vue'
export default {
    name: 'ContactoV',
    props: {
    },
    components: {
        HeaderV,
        FooterV
    },
    data() {
        return {
            nombreContacto: "",
            empresaContacto: '',
            telefonoContacto: '',
            emailContacto: '',
            ciudadContacto: '', 
            mensajeContacto: ''
        }
    },
    methods: {
        submitForm() {

            const formula =  JSON.stringify({
                nombreContacto: this.nombreContacto,
                empresaContacto: this.empresaContacto,
                telefonoContacto: this.telefonoContacto,
                emailContacto: this.emailContacto,
                ciudadContacto: this.ciudadContacto,
                mensajeContacto: this.mensajeContacto
            });
            
            
            axios.post("https://www.flexometal.com/prueba.php", formula)
                .then(response => response.data.id);

            this.nombreContacto = ""
            this.empresaContacto = ""
            this.telefonoContacto = ""
            this.emailContacto = ""
            this.ciudadContacto = ""
            this.mensajeContacto =  ''














        }
    }
}
</script>
<style lang="">
    
</style>