<template lang="">
     <HeaderV/>
   <div class="container">
        <div class="card text-start">
            <div class="card-header">
                <h4 class="card-title">POLÍTICAS</h4>
            </div>
            <div class="card-body">
                <h5 class="card-title">POLÍTICAS DE CALIDAD.</h5>

                <p class="card-text">
                    Flexometal establece el compromiso de dar soluciones en acero para lograr la satisfacción de sus
                    Clientes sustentada en la mejora de la calidad de sus procesos y gente competitiva en todos los
                    niveles, para cumplir con sus requerimientos, mediante la eficacia de un sistema de gestión de la
                    calidad ágil y de mejora continua.
                </p>

                <h5 class="card-title">POLÍTICAS DE SOLICITUD DE MATERIAL.</h5>
                <div class="container">
                    <p class="card-text">
                        <i class="fa-solid fa-share"></i>
                        Con el objetivo de ofrecer un excelente servicio en tiempo y
                        forma, Flexometal solicita a sus Clientes a emitir una orden de compra, que deberá ser entregada
                        al Asesor Comercial, ya sea en formato físico, correo electrónico, o cualquier otro medio de su
                        preferencia, en donde se especifique los productos a entregar por parte de Flexometal.
                        <br>
                        <i class="fa-solid fa-share"></i>
                        La orden indicará productos solicitados, características correspondientes tales como: calibre,
                        largos, especificaciones especiales si las hubiera.
                        <br>
                        <i class="fa-solid fa-share"></i>
                        Cliente establecerá en dicha orden de compra las cantidades solicitadas, que deberá estar
                        negociadas con su Asesor Comercial, en función del número de piezas por paquete correspondiente
                        a cada tipo de tubería, que es manejado por Flexometal (volumen mínimo de venta es 1 paquete por
                        medida).
                        <br>
                        <i class="fa-solid fa-share"></i>
                        En el caso de Medidas Especiales (largos o calibres diferentes a los Standard manejados por
                        Flexometal), Cliente deberá cubrir el 50% anticipo del importe total, esto con el fin de
                        asegurar su producción; el tiempo de entrega será establecido previamente con su Asesor
                        Comercial, en función de la capacidad de producción y respaldado con una Orden de compra con
                        Fecha de Entrega. En caso de No cumplir con retirar el material en la fecha establecida en la
                        Orden de Compra existirá una penalización por Almacenaje.
                        <br>
                        <i class="fa-solid fa-share"></i>
                        En el caso de Medida Especiales, Cliente deberá comprar a Flexometal, el excedente de producción
                        originado por su fabricación especial, en tal caso desde la negociación de dicha fabricación
                        Flexometal establecerá el % adicional a la cantidad solicitada.
                    </p>
                </div>

                <br>

                <h5 class="card-title">POLÍTICAS DE RECEPCIÓN DE PRODUCTO TERMINADO</h5>
                <div class="container">
                    <p class="card-text">
                        <i class="fa-solid fa-share"></i>
                        Es obligación del Cliente verificar y revisar el material al momento de recepción, constatar que
                        se trata del producto solicitado, calibres, cantidades, empaques, especificaciones especiales y
                        el buen estado general del mismo.
                        <br>
                        <i class="fa-solid fa-share"></i>
                        En caso de que existiera alguna diferencia deberá contactar inmediatamente con su Asesor
                        Comercial o en su defecto al Área de Servicio a Clientes en Flexometal, para reportar la
                        situación detectada.
                        <br>
                        <i class="fa-solid fa-share"></i>
                        A partir de la llegada de la unidad que traslada el producto terminado, Flexometal solicita su
                        descarga en las primeras 2 horas, esto con el fin de cuidar el buen manejo y cuidado del
                        producto terminado, sobre todo en la época de lluvias o invierno, ello buscando reducir al
                        mínimo efecto de humedad por condensación.
                    </p>
                </div>

                <br>

                <h5 class="card-title">POLÍTICA GENERAL DE RECLAMACIONES</h5>
                <div class="container">
                    <p class="card-text">
                        <i class="fa-solid fa-share"></i>
                        En caso de que exista algún problema con la calidad del producto recibido, deberá comunicarlo
                        directamente a su Asesor Comercial, quien a su vez informará al departamento correspondiente en
                        Flexometal, y se tendrá un período de 24 horas para la atención y acción de contención.
                        Posteriormente se tendrán 72 horas para establecer el plan de acciones correctivas y corregir de
                        raíz la problemática detectada.
                        <br>
                        <i class="fa-solid fa-share"></i>
                        Después de 1 día de entregado el producto, no se admiten devoluciones o quejas por diferencias
                        en cantidades o calidad del producto terminado.
                        <br>
                        <i class="fa-solid fa-share"></i>
                        Asimismo una vez procesado el producto por el cliente no se admiten devoluciones de producto
                        terminado.
                        <br>
                        <i class="fa-solid fa-share"></i>
                        Si se presenta cualquiera de los dos casos anteriores, el cliente recibirá la atención de parte
                        del departamento de Calidad de Flexometal, para corrección, prevención y seguimiento posterior
                        de la situación particular.
                    </p>
                </div>

                <br>
                
                <h6 class="card-title">
                    VARIACIÓN EN PESO DE FACTURA:
                </h6>
                <p class="card-text">
                    Una variación de hasta el + / - 1% del peso de bascula entre el cliente y Flexometal, por embarque
                    facturado será permitida, sin que proceda una reclamación. Después del 1% la empresa hará el
                    análisis de autorización en caso de existir un diferencial, lo cual debe ser confirmado con el
                    ticket de la báscula pública certificada de su preferencia.
                </p>

                <br>

                <h6 class="card-title">
                    RECLAMACIÓN POR OXIDACIÓN:
                </h6>
                <div class="container">
                    <p class="card-text">
                        <i class="fa-solid fa-share"></i>
                        No se aceptan reclamaciones por oxidación 1 día después de recibido el material; para cuidado
                        del producto terminado, se recomienda un almacenamiento adecuado, en lugares bajo techo,
                        ventilados y secos.
                        <br>
                        <i class="fa-solid fa-share"></i>
                        Deberá evitarse colocar el material directamente sobre el suelo; la tubería no deberá estar
                        expuesta a humedad ya que puede dañar el producto en un período de almacenamiento muy corto.
                        <br>
                        <i class="fa-solid fa-share"></i>
                        Flexometal no se hará responsable en reclamaciones de oxido, ya sea inmediata o posterior cuando
                        el material es almacenado a la intemperie, no se proteja de la humedad o se someta a cambios
                        bruscos de temperatura.
                    </p>
                </div>

                <br>

                <h6 class="card-title">
                    HUMEDAD EN TRASLADO DE PRODUCTO:
                </h6>
                <div class="container">
                    <p class="card-text">
                        <i class="fa-solid fa-share"></i>
                        Se aceptará una reclamación por tubería mojada en tránsito siempre y cuando se pueda observar y
                        comprobar humedad en el empaque; en caso de ser así, el Cliente deberá avisara a su Asesor
                        Comercial y anotar en la remisión correspondiente en presencia del transportista, y amparar su
                        reclamación.
                        <br>
                        <i class="fa-solid fa-share"></i>
                        Si no hay humedad por fuera del empaque, el acero deberá ser inspeccionado inmediatamente, para
                        verificar humedad por condensación en tránsito, si hay evidencia se reportará directamente a su
                        Asesor Comercial y anotar en la remisión en presencia del transportista.
                    </p>
                </div>

                <br>

                <h5 class="card-title">
                    POLÍTICAS DEL BUEN USO E INSTALACIÓN DEL PRODUCTO
                </h5>
                <p class="card-text">
                    Flexometal proporciona la información de sus productos como respaldo para la aplicación de los
                    productos, por lo que no se le podrá hacer responsable del mal uso que se le pudiera dar; se
                    recomienda la asesoría, bajo su propio cargo, cuenta y riesgo, de un especialista que verifique la
                    buena aplicación de los mismos.
                    <br>
                    Flexometal, bajo ninguna circunstancia, será responsable por la instalación y/o accesorios
                    utilizados para la instalación del o los productos comercializados. Renunciando a cualquier
                    garantía, expresa o implícita. Al hacer disponible esta información, Flexometal no está prestando
                    servicios de asesoría y no asume deberes con la presente información.
                    <br>
                    De igual modo, Flexometal no será responsable por alguna reclamación, demanda, lesión, pérdida,
                    gasto, costo, honorarios legales o responsabilidad de algún tipo que, en alguna forma, surja con el
                    uso de la información contenida en esta publicación, ya sea que tal reclamación, demanda, lesión,
                    pérdida, gasto, costo, honorarios legales o responsabilidad resulte directa o indirectamente de
                    alguna acción u omisión de Flexometal.
                    Cualquier parte que utilice la información contenida en este manual asume toda la responsabilidad
                    que surja de tal uso.
                    <br>
                    Puesto que existen riesgos asociados con el manejo, instalación o uso del acero y sus accesorios,
                    recomendamos que las partes involucradas en el manejo, instalación o uso revisen todas las hojas de
                    seguridad aplicables del material del fabricante, normas y reglamentos de la Secretaría del Trabajo
                    y Previsión Social y otras agencias de Gobierno que tengan jurisdicción sobre tal manejo,
                    instalación o uso, así como otras publicaciones relevantes de prácticas de construcción y/o
                    instalación industrial.
                </p>


            </div>
        </div>
    </div>
    <FooterV/>
</template>
<script>
import HeaderV from '@/components/HeaderV.vue'

import FooterV from '@/components/FooterV.vue'
export default {
    name: 'PoliticasV',
  props: {
  },  components: {
    HeaderV,
    FooterV
  }
}
</script>
<style lang="">
    
</style>