<template lang="">
<HeaderV/>
  <div class="container">
        <div class="card text-start">
            <div class="card-header">
                <h4 class="card-title">AVISO DE PRIVACIDAD</h4>
            </div>
            <div class="card-body">

                <div class="bd-content ps-lg-2">

                    <p class="card-text">
                        FLEXOMETAL SA DE CV, con domicilio en Carretera a Morelia # 540, Colonia San Agustin, en
                        Tlajomulco de Zúñiga, Jalisco, México, C.P. 45645, es responsable de recabar sus datos
                        personales, del uso que se le dé a los mismos y de su protección.
                    <br>
                        Su información personal será utilizada para proveerle de servicios o productos, o bien para
                        identificarlo en cualquier tipo de relación jurídica o de negocios con nosotros, además para
                        informarle sobre cambios en los mismos y evaluar la calidad del servicio que le brindamos.
                    </p>


                    <p class="card-text">
                        Para las finalidades antes mencionadas, requerimos obtener entre otros, los siguientes datos personales: Nombre, Dirección, Teléfono, correo electrónico, edad, sexo, lugar y fecha de nacimiento, R.F.C., C.U.R.P., etc., manifestando que no se le pedirán datos personales catalogados como sensibles según la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.
                    </p>

                

                    <p class="card-text">
                        Para ejercitar sus derechos de acceder, rectificar y cancelar sus datos personales, así como oponerse al tratamiento de los mismos o revocar el consentimiento que para tal fin nos haya otorgado, lo podrá realizar a través de los procedimientos implementados.
                    <br>
                        Para conocer dichos procedimientos, los requisitos y plazos, se puede poner en contacto a través de la cuenta de correo electrónico
                    </p>

                </div>

            </div>
        </div>
    </div>
    <FooterV/>
</template>
<script>
import HeaderV from '@/components/HeaderV.vue'

import FooterV from '@/components/FooterV.vue'
export default {
    name: 'AvisoPrivacidadV',
  props: {
  },  components: {
    HeaderV,
    FooterV
  }
}
</script>
<style lang="">
    
</style>