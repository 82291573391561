<template lang="">
     <HeaderV/>
  <div class="container">
        <div class="card text-start">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-9">
                        <div class="card text-start">
                            <div class="card-header">
                                <h4 class="card-title">
                                    COTIZACIONES
                                </h4>
                            </div>
                            <div class="card-body">
                                <p class="card-text">Escríbenos en el siguiente formulario y con gusto te atenderemos
                                </p>
                                <div class="container">

                                    <form @submit.prevent="submitForm">
                                        <div class="col-lg-12 form-group">

                                            <label for="exampleInputEmail1">Nombre</label>
                                       
                                                <input id="nombreContacto" type="text" v-model="nombreContacto" class="form-control"  placeholder="Juan Perez"/>
                                        </div>


                                        <div class="col-lg-12 form-group">
                                            <label for="exampleInputEmail1">Telefono</label>
                                            <input id="telefonoContacto" type="text" v-model="telefonoContacto" class="form-control"  placeholder="Telefono"/>
                                

                                        </div>


                                        <div class="col-lg-12 form-group">
                                            <label for="exampleInputEmail1">Email</label>
                                            <input id="emailContacto" type="text" v-model="emailContacto" class="form-control"  placeholder="Email"/>


                                        </div>


                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Material Requerido</label>
                                           
                                                <input id="materialContacto" type="text" v-model="materialContacto" class="form-control"  placeholder="Material"/>

                                            </div>
                                        </div>


                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Calibre</label>
                                              
                                                <input id="calibreContacto" type="text" v-model="calibreContacto" class="form-control"  placeholder="Calibre"/>

                                            </div>
                                        </div>


                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Requerimiento Especial</label>
                                              
                                                <input id="especialContacto" type="text" v-model="especialContacto" class="form-control"  placeholder="Especial"/>


                                            </div>
                                        </div>


                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Diámetro</label>
                                                <input id="diametroContacto" type="text" v-model="diametroContacto" class="form-control"  placeholder="Diametro"/>

                                            </div>
                                        </div>


                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Largo</label>
                                                
                                                <input id="largoContacto" type="text" v-model="largoContacto" class="form-control"  placeholder="Largo"/>

                                            </div>
                                        </div>


                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Tipo de Lámina</label>
                                                <input id="laminaContacto" type="text" v-model="laminaContacto" class="form-control"  placeholder="Tipo lamina"/>
                                               
                                            </div>
                                        </div>


                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Comentarios</label>
                                             
                                                    <textarea id="mensajeContacto" type="text" v-model="mensajeContacto" class="form-control"  placeholder="Escriba su mensaje"></textarea>

                                            </div>
                                        </div>


                                        <div class="col-lg-12">
                                            <div class="col-lg-12 text-center" style="margin-top: 10px;">
                                                   <button type="submit" class="btn btn-primary btn-md">Enviar</button>

                                            </div>
                                        </div>
                                    </form>




                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="card text-start">
                            <div class="card-body">
                                <div class="card text-start "
                                    style="background:rgb(10, 10, 80);color: #fff;margin-top: 10px;">
                                    <div class="card-header">
                                        <h5 class="card-title">PRODUCTOS</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="btn-group-vertical " role="group"
                                            aria-label="Vertical button group">
                                       
                            <router-link to="/rolada" class="boton">Tubería Rolada en Fría</router-link>
                           <router-link to="/galvanizado" class="boton">Tubo Galvanizado</router-link>
                           <router-link to="/tubog" class="boton">Tubo G-90</router-link>
                          <router-link to="/cedula" class="boton">Tubería Rolado en caliente & PTR</router-link>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <FooterV/>
</template>
<script>
import HeaderV from '@/components/HeaderV.vue'
import axios from 'axios'
import FooterV from '@/components/FooterV.vue'
export default {
    name: 'CotizacionV',
  props: {
  },
  components: {
    HeaderV,
    FooterV
  },
    data() {
        return {
            nombreContacto: "",      
            telefonoContacto: '',
            emailContacto: '',
            calibreContacto: '',
            especialContacto: '',
            diametroContacto: '',
            largoContacto: '',
            laminaContacto: '',
            materialContacto : '',
            mensajeContacto: ''
        }
    },
    methods: {
        submitForm() {

            const formula =  JSON.stringify({
                nombreContacto: this.nombreContacto,
                telefonoContacto: this.telefonoContacto,
                emailContacto: this.emailContacto,
               
            materialContacto: this.materialContacto,
            calibreContacto: this.calibreContacto,
            especialContacto: this.especialContacto,
            diametroContacto: this.diametroContacto,
            largoContacto: this.largoContacto,
            laminaContacto: this.laminaContacto,
            mensajeContacto: this.mensajeContacto,
            });
            
            
            axios.post("https://www.flexometal.com/prueba.php", formula)
                .then(response => response.data.id);

            this.nombreContacto = ""
            this.telefonoContacto = ""
            this.emailContacto = ""
            this.mensajeContacto =  ''
            this.calibreContacto = ''
            this.materialContacto = ''
            this.especialContacto= ''
            this.diametroContacto= ''
            this.largoContacto= ''
            this.laminaContacto= ''










        }
    }
}
</script>
<style>
.boton {
    background: rgb(10, 10, 80);
    color: #fff;
}
</style>