<template>
<div class="container"  >

  <Carousel :autoplay="5000" :wrap-around="true">
    <Slide v-for="(image, index) in images" :key="image.id">
    <img style="width: 100%;" :src="getImage(image.url)" />
    <button v-if="slidesCount > 1" @click="deleteImage(index)">x</button>
  </Slide>

    <template #addons>
      <Pagination />
    </template>
  </Carousel>

  

  </div>

  <div class="container" style="margin-top: 10px;">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title m-0 text-center">MERCADOS DE APLICACIÓN</h5>
        </div>
        <div class="card-body" style="height: auto;">
          <!-- <h6 class="card-title">Special title treatment</h6> -->
          <!-- <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
          <a href="#" class="btn btn-primary">Go somewhere</a> -->

          <div class="row">
         
            <div class="container2 col-lg-2">
              <router-link to="/mueblero">
                <img src="../assets/img-merc-apl/img-merc-mueb.jpg" alt="Avatar" class="image">
                <div class="overlay">
                  <div class="text ">MUEBLERO</div>
                </div>
              </router-link>
            </div>
            <div class="container2 col-lg-2">
              <router-link to="/automotriz">
                <img src="../assets/img-merc-apl/img-merc-auto.jpg" alt="Avatar" class="image">
                <div class="overlay">
                  <div class="text">AUTOMOTRIZ</div>
                </div>
              </router-link>
            </div>
            <div class="container2 col-lg-2">
              <router-link to="/macrotunel">
                <img src="../assets/img-merc-apl/img-merc-inver.jpg" alt="Avatar" class="image">
                <div class="overlay">
                  <div class="text ">MACROTUNEL INVERNADERO CASA SOMBRA</div>
                </div>
              </router-link>
            </div>
            <div class="container2 col-lg-2">
              <router-link to="/cerquero">
                <img src="../assets/img-merc-apl/img-merc-cerq.jpg" alt="Avatar" class="image">
                <div class="overlay">
                  <div class="text">CERQUERO</div>
                </div>
              </router-link>
            </div>
            <div class="container2 col-lg-2">
              <router-link to="/industria">
                <img src="../assets/img-merc-apl/img-merc-indus.jpg" alt="Avatar" class="image">
                <div class="overlay">
                  <div class="text">INDUSTRIAL</div>
                </div>
              </router-link>
            </div>
          </div>


        </div>
      </div>
    </div>

  </div>

  <div class="container" style="margin-top: 10px;">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div class="card">
        <div class="card-body" style="height: auto;">
          <h4>FLEXOMETAL</h4>

          <div class="row">
            <div class="col-sm-4">
              <div class="card">
                <div class="card-body" style="margin-top: -10px;">
                  <div class="col-sm-12">
                    <p class="card-text"> <i class="fa-regular fa-arrow-right"></i> Excelentes tiempos de respuesta.</p>
                  </div>
                  <div class="col-sm-12">
                    <p class="card-text"> <i class="fa-regular fa-arrow-right"></i> Servicio y calidad que distingue.
                    </p>
                  </div>
                  <div class="col-sm-12">
                    <p class="card-text"> <i class="fa-regular fa-arrow-right"></i> Desarrollo de nuevos productos.</p>
                  </div>
                  <div class="col-sm-12">
                    <p class="card-text"> <i class="fa-regular fa-arrow-right"></i> Asesoría Técnica. </p>
                  </div>
                  <div class="col-sm-12">
                    <p class="card-text"> <i class="fa-regular fa-arrow-right"></i> Especializada.</p>
                  </div>
                  <div class="col-sm-12">
                    <p class="card-text"> <i class="fa-regular fa-arrow-right"></i> Moderna.</p>
                  </div>
                  <div class="col-sm-12">
                    <p class="card-text"> <i class="fa-regular fa-arrow-right"></i> infraestructura.</p>
                  </div>
                  <a href="/empresa" class="btn btn-danger" style="margin-top: 10px;">  Conócenos  </a>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="card">
                <div class="card-body">
                  <p class="card-text">Amplia gama de productos </p>
                  <div class="row">
                    <div class="col-sm-6">
                      <p class="card-text">•Rolado Frio </p>
                    </div>
                    <div class="col-sm-6">
                      <p class="card-text">•PTR </p>
                    </div>
                    <div class="col-sm-6">
                      <p class="card-text">•Galvanizado con Flor</p>
                    </div>
                    <div class="col-sm-6">
                      <p class="card-text">•PTR Galvanizado</p>
                    </div>
                    <div class="col-sm-6">
                      <p class="card-text">•G90 </p>
                    </div>
                  </div>

                  <div class="col-sm-12">
                    <img src="../assets/img-tuberia-index.jpg" alt="Avatar" class="image">
                  </div>


                </div>
              </div>
            </div>

            <div class="col-sm-4">
              <div id="carouselExampleControls2" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="../assets/capacidad-index/img-capacidad-index-01.jpg" class="d-block w-100" alt="">
                  </div>
                  <div class="carousel-item ">
                    <img src="../assets/capacidad-index/img-capacidad-index-02.jpg" class="d-block w-100" alt="">
                  </div>
                  <div class="carousel-item ">
                    <img src="../assets/capacidad-index/img-capacidad-index-03.jpg" class="d-block w-100" alt="">
                  </div>
                  <div class="carousel-item ">
                    <img src="../assets/capacidad-index/img-capacidad-index-04.jpg" class="d-block w-100" alt="">
                  </div>
                  <!-- <div class="carousel-item" >
                    <img src="images/slide/Slide-3.jpg" class="d-block w-100" alt="">
                  </div> -->
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls2"
                  data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls2"
                  data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Pagination, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  name: 'HomeV',
  components: {
    Carousel,
    Slide,
    Pagination,
  },data() {
    return {
      images: [{id: 1,url:'Slide-1.jpg'}, {id: 2,url:'Slide-2.jpg'}]
    } 
  },methods: {
  getImage(imagePath) {
    return require('@/assets/slide/'+imagePath);
  }
}

})
</script>

<style>


.container2 {
  position: relative;
  width: 20%;
  margin: auto;
}

.image {
  /* object-fit: cover; */
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #5f6363;
}

.container2:hover .overlay {
  opacity: .8;
}

.text {
  color: white;
  font-size: small;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>


