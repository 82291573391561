<template>
    <div class="container" style="background-color: black;">
        <div class=" row" style="display: flex;  justify-content: center; ">

            <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="card" style="width: 18rem;border: 0ch;">
                    <div class="card-body " style="background-color: black;color: aliceblue;">
                        <h5 class="card-title">NUESTRA EMPRESA</h5>
                        <div class="row">
                            <div class="col-sm-4">
                                <img src="../assets/icon-company-incorporation.png" style="height: 70px;">
                            </div>
                            <div class="col-sm-8">
                                <div class="row">
                                    <router-link to="/empresa"><strong>• Nosotros</strong></router-link>
                                    <router-link to="/capacidad"><strong>• Instalaciones</strong></router-link>
                                    <!-- <router-link to="/bolsaTrabajo"><strong>• Bolsa de Trabajo.</strong></router-link> -->
                                    <router-link to="/preguntasFrecuentes"><strong>• Preguntas Frecuentes</strong></router-link>
                                
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="card" style="width: 18rem;border: 0ch;">
                    <div class="card-body " style="background-color: black;color: aliceblue;">
                        <h5 class="card-title">CONTÁCTANOS</h5>
                        <div class="row">
                            <div class="col-sm-4">
                                <img src="../assets/contact-icon.png" style="height: 70px;position: relative;">
                            </div>
                            <div class="col-sm-8">
                                <div class="row">
                                    <router-link to="/contacto"><strong>• Vía formulario</strong></router-link>
                                    <router-link to="/cotizacion"><strong>• Llámanos o llena un Formulario para una cotización</strong></router-link>
                           
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="card" style="width: 30rem;border: 0ch;">
                    <div class="card-body " style="background-color: black;color: aliceblue;">
                        <h5 class="card-title">UBICACIÓN</h5>
                        <div class="row">
                            <div class="col-sm-4">
                                <router-link to="/contacto"><strong>     <img src="../assets/embed-places-icon.png" style="height: 70px;position: relative;"></strong></router-link>
                               
                           

                            </div>
                            <div class="col-sm-8">
                                <div class="row">

                                    <p>Carretera a Morelia No. 540. Col. San Agustin. Tlajomulco de Zuñiga. Jalisco C.P.
                                        5645 </p>
                                    <p>Tel. 01 33 32715705 / 3188-2469 </p>
                                    <p>atencionclientes@flexometal.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12"></div><div class="col-sm-12 col-md-12 col-lg-12">
                <div class="card " style="background-color: black;" >
                    <div class="card-body">
                        <p class="parrafo">
                            Flexometal S.A. de C.V.
                        </p>
                        <p class="parrafo">
                            
                            <router-link to="/avisoPrivacidad"><strong>Aviso de privacidad</strong></router-link>
                            <!-- <router-link to="/avisoPrivacidad"><strong>Aviso de privacidad</strong></router-link> |  <router-link to="/politicas"><strong>Políticas</strong></router-link>  -->
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>

</template>


<style>
    a {
        color: aliceblue;
        font-size: 12px;
    }

    .parrafo {
        color: aliceblue;
        font-size: 15px;
        height: 10px;
    }
</style>


<script>
export default {
  name: 'FooterV',
  props: {
  }
}
</script>