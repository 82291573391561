<template lang="">
      <HeaderV/>
 <div class="container">
    <div class="card text-start">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-9">
            <div class="card text-start">
              <div class="card-header">
                <h4 class="card-title">TUBERÍA ROLADA EN FRÍA</h4>
              </div>
              <div class="card-body">
                <p class="card-text">
                  Las medidas que actualmente fabricamos son:
                </p>
                <div class="container">
                  <p class="card-text ventaja"><i class="fa-regular fa-arrow-right text-danger"></i>Tubos redondos de
                    <span class="text-danger">diámetro exterior</span>: ½", 5/8", ¾", 7/8", 1", 1¼", 1½", 2" y 3".
                    (1.315”, 40mm, 1 ¾”, 2 ½”)
                  </p>
                  <p class="card-text ventaja"><i class="fa-regular fa-arrow-right text-danger"></i>Tubos cuadrados de
                    <span class="text-danger">diámetro exterior</span>: ¾" x ¾", 1" x 1" y 1 ¼" x 1" ¼". (1 ½” x 1 ½”,
                    2”
                    x 2”, 3”x3”)
                  </p>
                  <p class="card-text ventaja"><i class="fa-regular fa-arrow-right text-danger"></i>Tubo rectangular de:
                    1" x ½" y 1 ¼" x ¾", 1 ½ x ½" (1 ¾” x ¾”, 3” x 1 ½”)</p>
                  <p class="card-text ventaja"><i class="fa-regular fa-arrow-right text-danger"></i>Tubo ovalado de 15mm
                    x
                    30mm. (15mm x 29mm)</p>
                  <p class="card-text ventaja"><i class="fa-regular fa-arrow-right text-danger"></i>Ángulo Camero en
                    calibre 14</p>
                </div>
                <p class="card-text" style="margin-top: 10px;">
                  Los calibres utilizados son <span class="text-danger">16, 18, 20, 22 y 23</span>. Calibres y
                  longitudes
                  diferentes a estos pueden fabricarse <span class="text-danger">previa consulta</span> con su Asesor
                  Comercial.
                </p>

                <div class="container">
                  <!--  -->
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                          PAQUETES PARA VENTA DE TUBO
                        </button>
                      </h2>
                      <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">

                          <div class="row">
                            <div class="col-lg-6">
                              <table class="table">
                                <thead >

                                  <tr>
                                    <td><strong>Tipo Tubo</strong>
                                    </td>
                                    <td><strong>Diámetro</strong>
                                    </td>
                                    <td><strong>Piezas</strong>
                                    </td>
                                  </tr>

                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Redondo</td>
                                    <td>1/2"</td>
                                    <td>100</td>
                                  </tr>
                                  <tr>
                                    <td rowspan="8">&nbsp;</td>
                                    <td>5/8"</td>
                                    <td>315</td>
                                  </tr>
                                  <tr>
                                    <td>3/4"</td>
                                    <td>217</td>
                                  </tr>
                                  <tr>
                                    <td>7/8"</td>
                                    <td>145</td>
                                  </tr>
                                  <tr>
                                    <td>1"</td>
                                    <td>115</td>
                                  </tr>
                                  <tr>
                                    <td>1 1/4"</td>
                                    <td>85</td>
                                  </tr>
                                  <tr>
                                    <td>1 1/2"</td>
                                    <td>77</td>
                                  </tr>
                                  <tr>
                                    <td>2"</td>
                                    <td>46</td>
                                  </tr>
                                  <tr>
                                    <td>3"</td>
                                    <td>25</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="col-lg-6">
                              <table class="table">
                                <thead >

                                  <tr>
                                    <td><strong>Tipo Tubo</strong>
                                    </td>
                                    <td><strong>Diámetro</strong>
                                    </td>
                                    <td><strong>Piezas</strong>
                                    </td>
                                  </tr>

                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Ovalado</td>
                                    <td>15 mm x 30mm</td>
                                    <td>100</td>
                                  </tr>
                                  <tr>
                                    <td rowspan="2">Rectangular</td>
                                    <td>1" x 1/2"</td>
                                    <td>195</td>
                                  </tr>
                                  <tr>
                                    <td>1 1/4" x 3/4"</td>
                                    <td>104</td>
                                  </tr>
                                  <tr>
                                    <td rowspan="3">Cuadrado</td>
                                    <td>3/4" x 3/4"</td>
                                    <td>256</td>
                                  </tr>
                                  <tr>
                                    <td>1" x 1"</td>
                                    <td>144</td>
                                  </tr>
                                  <tr>
                                    <td>1 1/4" x 1 1/4"</td>
                                    <td>110</td>
                                  </tr>
                                  <tr>
                                    <td>Ángulo</td>
                                    <td>1.91 m</td>
                                    <td>100</td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="col-lg-6">
                              <table class="table">
                                <thead >

                                  <tr>
                                    <td><strong>Tipo Tubo</strong>
                                    </td>
                                    <td><strong>Diámetro</strong>
                                    </td>
                                    <td><strong>Piezas</strong>
                                    </td>
                                  </tr>

                                </thead>
                                <tbody>
                                  <tr>
                                    <td rowspan="7">Redondo</td>
                                    <td>1.315"</td>
                                    <td>127</td>
                                  </tr>
                                  <tr>
                                    <td>1 1/2"</td>
                                    <td>77</td>
                                  </tr>
                                  <tr>
                                    <td>1 5/8"</td>
                                    <td>68</td>
                                  </tr>
                                  <tr>
                                    <td>1 7/8"</td>
                                    <td>68</td>
                                  </tr>
                                  <tr>
                                    <td>2 3/8"</td>
                                    <td>46</td>
                                  </tr>
                                  <tr>
                                    <td>2 7/8"</td>
                                    <td>28</td>
                                  </tr>
                                  <tr>
                                    <td>1 3/4"</td>
                                    <td>77</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          TUBO REDONDO
                        </button>
                      </h2>
                      <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <div class="row">

                            <div class="col-lg-6">
                              <table class="table">
                                <thead >

                                  <tr>
                                    <td><strong>Diámetro</strong>
                                    </td>
                                    <td><strong>Calibre</strong>
                                    </td>
                                    <td><strong>Kg / Pza</strong>
                                    </td>
                                    <td><strong>Largo</strong>
                                    </td>
                                  </tr>

                                </thead>
                                <tbody>
                                  <tr>
                                    <td>&nbsp;7/8 "</td>
                                    <td>16</td>
                                    <td>4.78</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;7/8 "</td>
                                    <td>18</td>
                                    <td>4</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;7/8 "</td>
                                    <td>20</td>
                                    <td>2.95</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;7/8 "</td>
                                    <td>22</td>
                                    <td>2.51</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;7/8</td>
                                    <td>23</td>
                                    <td>2.3</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 "</td>
                                    <td>16</td>
                                    <td>5.5</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 "</td>
                                    <td>18</td>
                                    <td>4.46</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 "</td>
                                    <td>20</td>
                                    <td>3.5</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 "</td>
                                    <td>22</td>
                                    <td>2.85</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 "</td>
                                    <td>23</td>
                                    <td>2.6</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 1/4 ''</td>
                                    <td>16</td>
                                    <td>6.97</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 1/4 ''</td>
                                    <td>18</td>
                                    <td>5.65</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 1/4 ''</td>
                                    <td>20</td>
                                    <td>4.27</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 1/4 ''</td>
                                    <td>22</td>
                                    <td>3.57</td>
                                    <td>6</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="col-lg-6">
                              <table class="table">
                                <thead >

                                  <tr>
                                    <td><strong>Diámetro</strong>
                                    </td>
                                    <td><strong>Calibre</strong>
                                    </td>
                                    <td><strong>Kg / Pza</strong>
                                    </td>
                                    <td><strong>Largo</strong>
                                    </td>
                                  </tr>

                                </thead>
                                <tbody>
                                  <tr>
                                    <td>&nbsp;1/2 ''</td>
                                    <td>18</td>
                                    <td>2.2</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;1/2 ''</td>
                                    <td>20</td>
                                    <td>1.76</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;1/2 ''</td>
                                    <td>22</td>
                                    <td>1.44</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;5/8 "</td>
                                    <td>16</td>
                                    <td>3.35</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;5/8 "</td>
                                    <td>18</td>
                                    <td>2.73</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;5/8 "</td>
                                    <td>20</td>
                                    <td>2.09</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;5/8 "</td>
                                    <td>22</td>
                                    <td>1.76</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;5/8 "</td>
                                    <td>23</td>
                                    <td>1.59</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;3/4 "</td>
                                    <td>16</td>
                                    <td>4.1</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;3/4 "</td>
                                    <td>18</td>
                                    <td>3.29</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;3/4 "</td>
                                    <td>20</td>
                                    <td>2.54</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;3/4 "</td>
                                    <td>22</td>
                                    <td>2.14</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;3/4 "</td>
                                    <td>23</td>
                                    <td>1.98</td>
                                    <td>6</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="col-lg-6">
                              <table class="table">
                                <thead >

                                  <tr>
                                    <td><strong>Diámetro</strong>
                                    </td>
                                    <td><strong>Calibre</strong>
                                    </td>
                                    <td><strong>Kg / Pza</strong>
                                    </td>
                                    <td><strong>Largo</strong>
                                    </td>
                                  </tr>

                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1 1/2 ''</td>
                                    <td>16</td>
                                    <td>8.44</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 1/2 ''</td>
                                    <td>18</td>
                                    <td>6.85</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 1/2 ''</td>
                                    <td>20</td>
                                    <td>5.3</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 1/2 ''</td>
                                    <td>22</td>
                                    <td>4.3</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 3/4 ''</td>
                                    <td>16</td>
                                    <td>10</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 3/4 ''</td>
                                    <td>18</td>
                                    <td>8.05</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 3/4 ''</td>
                                    <td>20</td>
                                    <td>6.1</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>2 "</td>
                                    <td>16</td>
                                    <td>11.36</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>2 "</td>
                                    <td>18</td>
                                    <td>9.25</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>2 "</td>
                                    <td>20</td>
                                    <td>6.94</td>
                                    <td>6</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="col-lg-6">
                              <table class="table">
                                <thead >

                                  <tr>
                                    <td><strong>Diámetro</strong>
                                    </td>
                                    <td><strong>Calibre</strong>
                                    </td>
                                    <td><strong>Kg / Pza</strong>
                                    </td>
                                    <td><strong>Largo</strong>
                                    </td>
                                  </tr>

                                </thead>
                                <tbody>
                                  <tr>
                                    <td>3"</td>
                                    <td>20</td>
                                    <td>10.52</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>3"</td>
                                    <td>18</td>
                                    <td>13.95</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>3"</td>
                                    <td>16</td>
                                    <td>17.4</td>
                                    <td>6</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          TUBO CUADRADO / TUBO OVALADO
                        </button>
                      </h2>
                      <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <div class="row">
                            <div class="col-lg-6">
                              <table class="table">
                                <thead >

                                  <tr>
                                    <td><strong>Diámetro</strong>
                                    </td>
                                    <td><strong>Calibre</strong>
                                    </td>
                                    <td><strong>Kg / Pza</strong>
                                    </td>
                                    <td><strong>Largo</strong>
                                    </td>
                                  </tr>

                                </thead>
                                <tbody>
                                  <tr>
                                    <td>3/4 " x 3/4 "</td>
                                    <td>16</td>
                                    <td>5.24</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>3/4 " x 3/4 "</td>
                                    <td>18</td>
                                    <td>4.2</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>3/4 " x 3/4 "</td>
                                    <td>20</td>
                                    <td>3.23</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>3/4 " x 3/4 "</td>
                                    <td>22</td>
                                    <td>2.71</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>3/4 " x 3/4 "</td>
                                    <td>23</td>
                                    <td>2.53</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 " x 1 "</td>
                                    <td>16</td>
                                    <td>6.97</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 " x 1 "</td>
                                    <td>18</td>
                                    <td>5.65</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 " x 1 "</td>
                                    <td>20</td>
                                    <td>4.3</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 " x 1 "</td>
                                    <td>22</td>
                                    <td>3.67</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 1/4" x 1 1/4"</td>
                                    <td>16</td>
                                    <td>9.26</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 1/4" x 1 1/4"</td>
                                    <td>18</td>
                                    <td>7.46</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 1/4" x 1 1/4"</td>
                                    <td>20</td>
                                    <td>5.64</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 1/4" x 1 1/4"</td>
                                    <td>22</td>
                                    <td>4.92</td>
                                    <td>6</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="col-lg-6">
                              <table class="table">
                                <thead >

                                  <tr>
                                    <td><strong>Diámetro</strong>
                                    </td>
                                    <td><strong>Calibre</strong>
                                    </td>
                                    <td><strong>Kg / Pza</strong>
                                    </td>
                                    <td><strong>Largo</strong>
                                    </td>
                                  </tr>

                                </thead>
                                <tbody>
                                  <tr>
                                    <td>15mm x 30mm</td>
                                    <td>16</td>
                                    <td>5.24</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>15mm x 30mm</td>
                                    <td>18</td>
                                    <td>4.2</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>15mm x 30mm</td>
                                    <td>20</td>
                                    <td>3.23</td>
                                    <td>6</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                          TUBO RECTANGULAR
                        </button>
                      </h2>
                      <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <div class="row">
                            <div class="col-lg-12">
                              <table class="table">
                                <thead >

                                  <tr>
                                    <td><strong>Diámetro</strong>
                                    </td>
                                    <td><strong>Calibre</strong>
                                    </td>
                                    <td><strong>Kg / Pza</strong>
                                    </td>
                                    <td><strong>Largo</strong>
                                    </td>
                                  </tr>

                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1 " x 1/2 "</td>
                                    <td>18</td>
                                    <td>4.2</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 " x 1/2 "</td>
                                    <td>20</td>
                                    <td>3.23</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 " x 1/2 "</td>
                                    <td>22</td>
                                    <td>2.71</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 1/4" x 3/4"</td>
                                    <td>18</td>
                                    <td>5.65</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 1/4" x 3/4"</td>
                                    <td>20</td>
                                    <td>4.3</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 1/4" x 3/4"</td>
                                    <td>22</td>
                                    <td>3.61</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 1/4" x 3/4"</td>
                                    <td>23</td>
                                    <td>3.31</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1 1/4" x 3/4"</td>
                                    <td>23</td>
                                    <td>3.31</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1/2" / 1 ½”</td>
                                    <td>22</td>
                                    <td>4.42</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1/2" / 1 ½”</td>
                                    <td>20</td>
                                    <td>4.17</td>
                                    <td>6</td>
                                  </tr>
                                  <tr>
                                    <td>1/2" / 1 ½”</td>
                                    <td>18</td>
                                    <td>4.06</td>
                                    <td>6</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--  -->
                </div>
              </div>
            </div>


          </div>
          <div class="col-lg-3">
            <div class="card text-start">
              <div class="card-body">
                <router-link to="/mueblero">
                  <img src="../assets/img-difmerc.jpg" class="img-responsive" alt="Image" style="width: 100%;">
                </router-link>

                <div class="card text-start " style="background:rgb(10, 10, 80);color: #fff;margin-top: 10px;">
                  <div class="card-header">
                    <h5 class="card-title">PRODUCTOS</h5>
                  </div>
                  <div class="card-body">
                    <div class="btn-group-vertical " role="group" aria-label="Vertical button group">
                      <router-link to="/rolada" class="boton">Tubería Rolada en Fría</router-link>
                           <router-link to="/galvanizado" class="boton">Tubo Galvanizado</router-link>
                           <router-link to="/tubog" class="boton">Tubo G-90</router-link>
                          <router-link to="/cedula" class="boton">Tubería Rolado en caliente & PTR</router-link>
                    </div>

                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <FooterV/>
</template>
<script>
import HeaderV from '@/components/HeaderV.vue'

import FooterV from '@/components/FooterV.vue'
export default {
    name: 'RoladaV',
  props: {
  }, components: {
    HeaderV,
    FooterV
  }
}
</script>
<style>
/* .ventaja {
  line-height: 0px;
} */

.boton {
  background: rgb(10, 10, 80);
  color: #fff;
}

thead{
 background-color: rgb(176, 0, 0); 
 color: #fff;
}
.accordion-button{
  background: rgb(56, 56, 56);
  color: #fff;
}
</style>